<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">考试信息</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="培训名称" class="searchboxItem ci-full">
              <span class="itemLabel">培训名称:</span>
              <el-input
                v-model="searchData.projectName"
                type="text"
                size="small"
                clearable
                placeholder="请输入培训名称"
              />
            </div>
            <div
              title="学员信息"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">学员信息:</span>
              <el-select
                v-model="searchData.userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in userList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="考试结果" class="searchboxItem ci-full">
              <span class="itemLabel">考试结果:</span>
              <el-select
                v-model="searchData.paperState"
                placeholder="请选择考试结果"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in paperStateTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="考试类型" class="searchboxItem ci-full">
              <span class="itemLabel">考试类型:</span>
              <el-select
                v-model="searchData.examType"
                placeholder="请选择考试类型"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in PaperExamTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="培训名称"
                align="center"
                prop="projectName"
                show-overflow-tooltip
                width="160"
              />

              <el-table-column
                label="姓名"
                align="center"
                prop="userName"
                show-overflow-tooltip
                width="100"
              />

              <el-table-column
                label="身份证"
                align="center"
                prop="idcard"
                show-overflow-tooltip
              />
              <el-table-column
                label="手机号"
                align="center"
                prop="mobile"
                show-overflow-tooltip
              />
              <el-table-column
                label="考试类型"
                align="center"
                prop="examType"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ $setDictionary("PAPER_EXAM_TYPE", scope.row.examType) }}
                </template>
              </el-table-column>
              <el-table-column
                label="考试时间"
                align="center"
                prop="examTime"
                show-overflow-tooltip
                width="140"
              >
                <template slot-scope="scope">
                  {{ scope.row.examTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="考试成绩"
                align="center"
                prop="paperScore"
                show-overflow-tooltip
              />
              <el-table-column
                label="是否及格"
                align="center"
                prop="compName"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.paperState ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    :disabled="scope.row.examType == '10'"
                    @click="
                      seeCurriculumInfo(
                        scope.row.userId,
                        scope.row.projectCourseId,
                        scope.row.orderId
                      )
                    "
                    >查看订单</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 查看订单信息 - 弹框 -->
    <el-dialog title="订单信息" :visible.sync="invoicedialog" top="10%"
      ><div class="ovy-a">
        <el-descriptions title="订单详情" column="2">
          <el-descriptions-item label="课程名称:">{{
            orderInfo.productName
          }}</el-descriptions-item>
          <el-descriptions-item label="订单编号:">{{
            orderInfo.orderSn
          }}</el-descriptions-item>
          <el-descriptions-item label="订单日期:">{{
            orderInfo.createTime | moment
          }}</el-descriptions-item>
          <el-descriptions-item label="支付方式:">{{
            orderInfo.paymentMethod == "1"
              ? "苹果支付"
              : orderInfo.paymentMethod == "2"
              ? "支付宝支付"
              : "微信支付"
          }}</el-descriptions-item>
          <el-descriptions-item label="补考类型:"
            >补考费用</el-descriptions-item
          >
          <el-descriptions-item label="支付日期:">{{
            orderInfo.payTime | moment
          }}</el-descriptions-item>
          <el-descriptions-item label="订单金额:"
            >{{ orderInfo.orderMoney }}元</el-descriptions-item
          >
        </el-descriptions>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="orderInfoNo">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import QRCode from "qrcodejs2"; // 引入qrcode
export default {
  name: "Partner/partnerList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchData: {
        projectName: "", // 培训名称
        examType: "", // 考试类型
        paperState: "",
        userId: "",
      },
      //考试类型下拉数据
      PaperExamTypeList: [],
      //考试结果下拉数据
      paperStateTypeList: [
        {
          label: "合格",
          value: true,
        },
        {
          label: "不合格",
          value: false,
        },
      ],
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      invoicedialog: false,
      orderInfo: {},
    };
  },
  created() {
    this.getPaperExamTypeList();
    this.superUserSelect();
    this.getTableHeight();
  },
  methods: {
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    // 获取 -- 考试类型下拉码值
    getPaperExamTypeList() {
      //证书领取
      const PaperExamType = this.$setDictionary("PAPER_EXAM_TYPE", "list");
      for (const key in PaperExamType) {
        this.PaperExamTypeList.push({
          value: key,
          label: PaperExamType[key],
        });
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperState: this.searchData.paperState,
      };
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }
      if (this.searchData.examType) {
        params.examType = this.searchData.examType;
      }
      if (this.searchData.userId) {
        params.userId = this.searchData.userId;
      }
      this.doFetch({
        url: "/run/project/paper/relation/pageList",
        params,
        pageNum,
      });
    },
    // 查看订单
    seeCurriculumInfo(userId, projectCourseId, orderId) {
      this.invoicedialog = true;
      this.$post("/run/project/paper/relation/orderMain", {
        userId,
        projectCourseId,
        orderId,
      }).then((res) => {
        ;
        this.orderInfo = {
          ...res.data,
        };
      });
    },
    orderInfoNo() {
      this.invoicedialog = false;
      this.orderInfo = {};
    },
    // 列表高度计算
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  // 离开这个页面也要清除
  beforeDestroy() {
    clearInterval(this.getInfosSetInterval); // 清除定时器
    this.getInfosSetInterval = null;
  },
  watch: {},
};
</script>
<style lang="less" scope>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
<style lang="less">
.el-descriptions-row .el-descriptions-item__container {
  margin-top: 15px !important;
}
</style>
